import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import PostLayout from '../layouts/post';

export default function BlogPost({ data }) {
  const post = data.markdownRemark;
  return (
    <PostLayout showAuthor>
      <Helmet title={`${post.frontmatter.title} | Sidd`} />
      <h1>{post.frontmatter.title}</h1>
      <p>
        <small>Published on {post.frontmatter.date}</small>
      </p>
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </PostLayout>
  );
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
      }
    }
  }
`;
